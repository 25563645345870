var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isBillMode)?_c('h2',[_vm._v(_vm._s(_vm.$t("bill.form.orderItems")))]):_vm._e(),(_vm.isReceivableMode)?_c('h2',[_vm._v(_vm._s(_vm.$t("bill.form.receivableItems")))]):_vm._e(),_c('el-table',{ref:"multipleTable",staticStyle:{"width":"100%"},attrs:{"data":_vm.addNewItemRow(_vm.orderItems),"row-class-name":_vm.tableRowClassName,"summary-method":_vm.getSummaries,"show-summary":""}},[(_vm.displaySelectedCheckbox && _vm.isBillMode)?_c('el-table-column',{attrs:{"width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('CheckBox',{attrs:{"selectType":scope.row.selectType},on:{"click":selectType => {
              _vm.$emit('switchOrderItemSelectType', {
                selectType,
                orderItemId: scope.row.id
              });
            }}})]}}],null,false,3201634373)}):_vm._e(),_c('el-table-column',{attrs:{"label":_vm.$t('column.student'),"property":"studentName","width":"160"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.type === 'controlAddRow')?_c('el-button',{attrs:{"size":"mini","type":"primary"},on:{"click":function($event){return _vm.$emit('addOtherItem', _vm.otherItem)}}},[(_vm.isBillMode)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("bill.form.addOrderItems"))+" ")]):_vm._e(),(_vm.isReceivableMode)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("bill.form.addReceivableItems"))+" ")]):_vm._e()]):[(_vm.isEditItem(scope.row))?_c('span',[_c('StudentSelector',{attrs:{"value":scope.row.studentId,"students":_vm.students},on:{"change":({ user_id, name }) => {
                  _vm.$emit('setOtherItem', scope.$index, {
                    studentId: user_id,
                    studentName: name
                  });
                }}})],1):_c('span',[_vm._v(" "+_vm._s(scope.row.studentName)+" ")])]]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('column.serviceName'),"property":"title"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.isEditItem(scope.row))?_c('span',[_c('el-input',{attrs:{"value":scope.row.title},on:{"input":title => {
                _vm.$emit('setOtherItem', scope.$index, { title });
              }}})],1):_c('span',[_vm._v(" "+_vm._s(scope.row.title)+" ")])]}}])}),(_vm.isReceivableMode)?_c('el-table-column',{attrs:{"label":"款項處理日","property":"remark"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.isEditItem(scope.row))?_c('span',[_c('el-date-picker',{attrs:{"value":scope.row.receivableDate,"type":"date","value-format":"yyyy-MM-dd"},on:{"input":receivableDate => {
                _vm.$emit('setOtherItem', scope.$index, { receivableDate });
              }}})],1):_c('span',[_vm._v(" "+_vm._s(scope.row.receivableDate)+" ")])]}}],null,false,2894329640)}):_vm._e(),(_vm.isBillMode)?_c('el-table-column',{attrs:{"label":_vm.$t('column.remark'),"property":"remark"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.isEditItem(scope.row))?_c('span',[_c('el-input',{attrs:{"value":scope.row.remark},on:{"input":remark => {
                _vm.$emit('setOtherItem', scope.$index, { remark });
              }}})],1):_c('span',[_vm._v(" "+_vm._s(scope.row.remark)+" ")])]}}],null,false,1585013768)}):_vm._e(),_c('el-table-column',{attrs:{"label":_vm.$t('column.price')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.isEditItem(scope.row))?_c('span',[_c('InputMoney',{attrs:{"money":scope.row.price,"currency":scope.row.priceCurrency},on:{"changeMoney":price => {
                _vm.$emit('setOtherItem', scope.$index, { price });
              },"changeCurrency":currency => {
                _vm.$emit('setOtherItem', scope.$index, {
                  priceCurrency: currency
                });
              }}})],1):_c('div',{staticClass:"price"},[_c('div',{staticClass:"billPrice"},[_vm._v(" "+_vm._s(scope.row.displayPriceByCurrency)+" ")]),(
              scope.row.priceCurrency &&
                scope.row.priceCurrency !==
                  _vm.$store.getters['bill/billCurrency']
            )?_c('div',{staticClass:"originalPrice"},[_vm._v(" ≈"+_vm._s(scope.row.displayPrice)+" ")]):_vm._e()])]}}])}),(_vm.viewMode && _vm.isReceivableMode)?_c('el-table-column',{attrs:{"label":"處理狀態","width":"100px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
            scope.row.order_item &&
              scope.row.order_item.order.status === _vm.EnumBillStatuses.finished
          )?_c('el-tooltip',{attrs:{"content":"已付款","placement":"top","popper-class":"tooltipColor"}},[_c('span',{staticClass:"action-icon-without-pointer"},[_c('i',{staticClass:"fas fa-check"})])]):(scope.row.order_item)?_c('el-tooltip',{attrs:{"content":"已建立帳單","placement":"top","popper-class":"tooltipColor"}},[_c('router-link',{attrs:{"to":{
              name: 'billView',
              params: { billId: scope.row.order_item.order.id }
            }}},[_c('i',{staticClass:"fas fa-eye"})])],1):_vm._e()]}}],null,false,3952605424)}):_vm._e(),(_vm.action)?_c('el-table-column',{attrs:{"label":_vm.$t('column.action'),"width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.type === 'editCustomItem')?_c('div',[(scope.row.edit)?_c('el-tooltip',{attrs:{"value":true,"content":_vm.$t('message.remeberSave'),"placement":"top","popper-class":"tooltipColor"}},[_c('span',{key:"check",staticClass:"action-icon",on:{"click":() => {
                  _vm.$emit('setOtherItem', scope.$index, { edit: false });
                }}},[_c('i',{staticClass:"fas fa-check"})])]):[(scope.row.status === _vm.EnumBillStatuses.finished)?_c('el-tooltip',{attrs:{"content":"已建立帳單，無法修改","placement":"top","popper-class":"tooltipColor"}},[_c('span',{staticClass:"action-icon-without-pointer"},[_c('i',{staticClass:"fas fa-question-circle"})])]):_c('span',{key:"edit",staticClass:"action-icon",on:{"click":() => {
                  _vm.$emit('setOtherItem', scope.$index, { edit: true });
                }}},[_c('i',{staticClass:"fas fa-edit"})])],(scope.row.status !== _vm.EnumBillStatuses.finished)?_c('span',{staticClass:"action-icon warning-icon",on:{"click":() => {
                _vm.$emit('removeOtherOrderItem', scope.$index);
              }}},[_c('i',{staticClass:"el-icon-delete"})]):_vm._e()],2):_c('div',[(scope.row.type !== 'controlAddRow')?_c('span',{staticClass:"action-icon",on:{"click":() => {
                _vm.openEnrollmentPriceForm(scope.row);
              }}},[_c('i',{staticClass:"fas fa-edit"})]):_vm._e()])]}}],null,false,3973191497)}):_vm._e()],1),(_vm.isOrderItemCurrencyMultiple)?_c('MultipleCurrencyTotalPrompt'):_vm._e(),_c('EnrollmentPriceForm',{attrs:{"enrollment":_vm.enrollment,"visible":_vm.visibleEnrollmentPriceForm},on:{"closeEnrollmentPriceForm":_vm.closeEnrollmentPriceForm,"handleOk":customPrice => {
        _vm.$emit('updatePriceOfEnrollment', _vm.enrollment.id, customPrice);
        _vm.closeEnrollmentPriceForm();
      }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }