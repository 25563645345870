<template>
  <el-dialog
    :title="$t('bill.modifyTuition')"
    :visible="visible"
    width="40%"
    :before-close="
      () => {
        $emit('closeEnrollmentPriceForm');
      }
    "
  >
    <h4>
      {{ $t("bill.courseForm.courseName") }}{{ enrollment.title }}
      <span @click="directEnrollForm">
        <i class="fas fa-edit action-icon" />
      </span>
    </h4>
    <el-row class="rowGap" :gutter="8">
      <el-col :span="6">
        {{ $t("bill.courseForm.originPrice") }}
      </el-col>
      <el-col :span="10">
        {{ enrollment.displayPrice }}
        <router-link
          :to="{
            name: 'courseSessionsEdit',
            params: { id: enrollment.courseSessionId }
          }"
        >
          <i class="fas fa-edit action-icon" />
        </router-link>
      </el-col>
    </el-row>
    <el-row class="rowGap" :gutter="8">
      <el-col :span="6">{{ $t("bill.courseForm.shouldPayAmount") }}</el-col>
      <el-col :span="10">
        <InputMoney
          :money="editEnrollment.price"
          :currency="editEnrollment.priceCurrency"
          @changeMoney="
            price => {
              editEnrollment.price = price;
            }
          "
          @changeCurrency="
            currency => {
              editEnrollment.priceCurrency = currency;
            }
          "
        />
      </el-col>
    </el-row>
    <el-row class="rowGap" :gutter="8">
      <el-col :span="6">{{ $t("bill.courseForm.remark") }}</el-col>
      <el-col :span="18">
        <el-input size="small" v-model="editEnrollment.remark" />
      </el-col>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <el-button @click="$emit('closeEnrollmentPriceForm')">
        {{ $t("button.cancel") }}
      </el-button>
      <el-button type="primary" @click="$emit('handleOk', editEnrollment)">
        {{ $t("button.confirm") }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { InputMoney } from "@/components/input";

export default {
  components: {
    InputMoney
  },
  props: ["visible", "closeEnrollmentPriceForm", "enrollment"],
  data() {
    return {
      editEnrollment: {}
    };
  },
  watch: {
    enrollment(enrollment) {
      this.editEnrollment = enrollment;
    }
  },
  methods: {
    async directEnrollForm() {
      try {
        await this.$confirm(
          this.$t("bill.form.doYouWantToGoToEnrollForm"),
          this.$t("message.notice"),
          {
            confirmButtonText: this.$t("message.continue"),
            cancelButtonText: this.$t("message.cancel"),
            type: "warning"
          }
        );
      } catch (e) {
        return false;
      }

      this.$router.push({
        name: "EnrollmentDetail",
        params: { id: this.enrollment.id }
      });
    }
  }
};
</script>
