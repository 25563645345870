<template>
  <div>
    <h2 v-if="isBillMode">{{ $t("bill.form.orderItems") }}</h2>
    <h2 v-if="isReceivableMode">{{ $t("bill.form.receivableItems") }}</h2>
    <el-table
      ref="multipleTable"
      :data="addNewItemRow(orderItems)"
      style="width: 100%"
      :row-class-name="tableRowClassName"
      :summary-method="getSummaries"
      show-summary
    >
      <el-table-column v-if="displaySelectedCheckbox && isBillMode" width="80">
        <template slot-scope="scope">
          <CheckBox
            :selectType="scope.row.selectType"
            @click="
              selectType => {
                $emit('switchOrderItemSelectType', {
                  selectType,
                  orderItemId: scope.row.id
                });
              }
            "
          />
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('column.student')"
        property="studentName"
        width="160"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            v-if="scope.row.type === 'controlAddRow'"
            @click="$emit('addOtherItem', otherItem)"
          >
            <span v-if="isBillMode">
              {{ $t("bill.form.addOrderItems") }}
            </span>
            <span v-if="isReceivableMode">
              {{ $t("bill.form.addReceivableItems") }}
            </span>
          </el-button>
          <template v-else>
            <span v-if="isEditItem(scope.row)">
              <StudentSelector
                :value="scope.row.studentId"
                :students="students"
                @change="
                  ({ user_id, name }) => {
                    $emit('setOtherItem', scope.$index, {
                      studentId: user_id,
                      studentName: name
                    });
                  }
                "
              />
            </span>
            <span v-else>
              {{ scope.row.studentName }}
            </span>
          </template>
        </template>
      </el-table-column>
      <el-table-column :label="$t('column.serviceName')" property="title">
        <template slot-scope="scope">
          <span v-if="isEditItem(scope.row)">
            <el-input
              :value="scope.row.title"
              @input="
                title => {
                  $emit('setOtherItem', scope.$index, { title });
                }
              "
            />
          </span>
          <span v-else>
            {{ scope.row.title }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="isReceivableMode"
        label="款項處理日"
        property="remark"
      >
        <template slot-scope="scope">
          <span v-if="isEditItem(scope.row)">
            <el-date-picker
              :value="scope.row.receivableDate"
              @input="
                receivableDate => {
                  $emit('setOtherItem', scope.$index, { receivableDate });
                }
              "
              type="date"
              value-format="yyyy-MM-dd"
            />
          </span>
          <span v-else>
            {{ scope.row.receivableDate }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="isBillMode"
        :label="$t('column.remark')"
        property="remark"
      >
        <template slot-scope="scope">
          <span v-if="isEditItem(scope.row)">
            <el-input
              :value="scope.row.remark"
              @input="
                remark => {
                  $emit('setOtherItem', scope.$index, { remark });
                }
              "
            />
          </span>
          <span v-else>
            {{ scope.row.remark }}
          </span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('column.price')">
        <template slot-scope="scope">
          <span v-if="isEditItem(scope.row)">
            <InputMoney
              :money="scope.row.price"
              :currency="scope.row.priceCurrency"
              @changeMoney="
                price => {
                  $emit('setOtherItem', scope.$index, { price });
                }
              "
              @changeCurrency="
                currency => {
                  $emit('setOtherItem', scope.$index, {
                    priceCurrency: currency
                  });
                }
              "
            />
          </span>
          <div v-else class="price">
            <div class="billPrice">
              {{ scope.row.displayPriceByCurrency }}
            </div>
            <div
              v-if="
                scope.row.priceCurrency &&
                  scope.row.priceCurrency !==
                    $store.getters['bill/billCurrency']
              "
              class="originalPrice"
            >
              ≈{{ scope.row.displayPrice }}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="viewMode && isReceivableMode"
        label="處理狀態"
        width="100px"
      >
        <template slot-scope="scope">
          <el-tooltip
            v-if="
              scope.row.order_item &&
                scope.row.order_item.order.status === EnumBillStatuses.finished
            "
            content="已付款"
            placement="top"
            popper-class="tooltipColor"
          >
            <span class="action-icon-without-pointer">
              <i class="fas fa-check" />
            </span>
          </el-tooltip>
          <el-tooltip
            v-else-if="scope.row.order_item"
            content="已建立帳單"
            placement="top"
            popper-class="tooltipColor"
          >
            <router-link
              :to="{
                name: 'billView',
                params: { billId: scope.row.order_item.order.id }
              }"
            >
              <i class="fas fa-eye" />
            </router-link>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column v-if="action" :label="$t('column.action')" width="100">
        <template slot-scope="scope">
          <div v-if="scope.row.type === 'editCustomItem'">
            <el-tooltip
              v-if="scope.row.edit"
              :value="true"
              :content="$t('message.remeberSave')"
              placement="top"
              popper-class="tooltipColor"
            >
              <span
                key="check"
                class="action-icon"
                @click="
                  () => {
                    $emit('setOtherItem', scope.$index, { edit: false });
                  }
                "
              >
                <i class="fas fa-check" />
              </span>
            </el-tooltip>
            <template v-else>
              <el-tooltip
                v-if="scope.row.status === EnumBillStatuses.finished"
                content="已建立帳單，無法修改"
                placement="top"
                popper-class="tooltipColor"
              >
                <span class="action-icon-without-pointer">
                  <i class="fas fa-question-circle" />
                </span>
              </el-tooltip>
              <span
                v-else
                key="edit"
                class="action-icon"
                @click="
                  () => {
                    $emit('setOtherItem', scope.$index, { edit: true });
                  }
                "
              >
                <i class="fas fa-edit" />
              </span>
            </template>
            <span
              v-if="scope.row.status !== EnumBillStatuses.finished"
              class="action-icon warning-icon"
              @click="
                () => {
                  $emit('removeOtherOrderItem', scope.$index);
                }
              "
            >
              <i class="el-icon-delete" />
            </span>
          </div>
          <div v-else>
            <span
              class="action-icon"
              v-if="scope.row.type !== 'controlAddRow'"
              @click="
                () => {
                  openEnrollmentPriceForm(scope.row);
                }
              "
            >
              <i class="fas fa-edit" />
            </span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <MultipleCurrencyTotalPrompt v-if="isOrderItemCurrencyMultiple" />
    <EnrollmentPriceForm
      :enrollment="enrollment"
      :visible="visibleEnrollmentPriceForm"
      @closeEnrollmentPriceForm="closeEnrollmentPriceForm"
      @handleOk="
        customPrice => {
          $emit('updatePriceOfEnrollment', enrollment.id, customPrice);
          closeEnrollmentPriceForm();
        }
      "
    />
  </div>
</template>

<script>
import "@/assets/scss/style.scss";
import MultipleCurrencyTotalPrompt from "@/components/prompt/MultipleCurrencyTotalPrompt";
import EnrollmentPriceForm from "@/components/bill/EnrollmentPriceForm.vue";
import { StudentSelector } from "@/components/selector";
import CheckBox from "@/components/bill/CheckBox.vue";
import { InputMoney } from "@/components/input";
import moneyMixin from "@/mixins/money";
import { EnumBillStatuses } from "@/enums";

export default {
  props: {
    studentsByParent: {
      type: Array,
      default: () => []
    },
    students: {
      type: Array,
      default: () => []
    },
    orderItems: {
      type: Array,
      default: () => []
    },
    displaySelectedCheckbox: {
      type: Boolean,
      default: true
    },
    viewMode: {
      type: Boolean,
      default: false
    },
    action: {
      type: Boolean,
      default: true
    },
    showTotalPrice: {
      type: Number,
      default: null
    },
    billIsCreated: {
      type: Boolean,
      default: false
    },
    isPaid: {
      type: Boolean,
      default: false
    },
    editor: {
      type: Boolean,
      default: false
    },
    isBillMode: {
      type: Boolean,
      default: false
    },
    isReceivableMode: {
      type: Boolean,
      default: false
    }
  },
  mixins: [moneyMixin],
  computed: {
    EnumBillStatuses() {
      return EnumBillStatuses;
    }
  },
  components: {
    MultipleCurrencyTotalPrompt,
    InputMoney,
    StudentSelector,
    EnrollmentPriceForm,
    CheckBox
  },
  data() {
    return {
      enrollment: {},
      visibleEnrollmentPriceForm: false,
      isOrderItemCurrencyMultiple: false,
      otherItem: {
        edit: true,
        type: "editCustomItem",
        id: 0,
        studentId: null,
        studentName: "",
        title: "",
        price: 0,
        priceCurrency: this.$store.getters["bill/billCurrency"],
        remark: ""
      }
    };
  },
  methods: {
    addNewItemRow(orderItems) {
      if (this.editor) {
        return [...orderItems, { type: "controlAddRow" }];
      }
      return orderItems;
    },
    openEnrollmentPriceForm(enrollment) {
      if (this.isPaid || this.billIsCreated) {
        this.$message.warning(
          this.$t("如果要修改金額請編輯，或刪除此帳單後重新建立！")
        );
        return;
      }
      switch (enrollment.type) {
        case "default":
          this.enrollment = enrollment;
          this.visibleEnrollmentPriceForm = true;
          break;
        case "custom":
          this.$message.warning(
            this.$t("如要修改家教課金額請由下方帳單明細直接修改 Lesson Log")
          );
          return;
        case "App\\Entities\\ReceivableOrderItem":
          this.$message.warning(
            this.$t("如要修改分期付款的金額請到未來款項頁面修改")
          );
          return;
        default:
      }
    },
    closeEnrollmentPriceForm() {
      this.visibleEnrollmentPriceForm = false;
    },
    getEffectiveOrderItem(orderItems) {
      return orderItems
        .filter(orderItem => orderItem.selectType !== "unSelected")
        .filter(orderItem => orderItem.type !== "controlAddRow")
        .filter(orderItem => !orderItem.edit);
    },
    calculationTotalPrice(orderItems) {
      if (this.showTotalPrice) return this.showTotalPrice;
      const priceByCurrency = this.getEffectiveOrderItem(
        orderItems
      ).map(orderItem => Number(orderItem.priceByCurrency));
      const billTotalPrice = priceByCurrency.reduce(
        (prev, curr) => prev + curr,
        0
      );
      this.$emit("setBillTotalPrice", billTotalPrice);
      return billTotalPrice;
    },
    getSummaries(param) {
      const { columns, data: orderItems } = param;
      const sums = [];
      columns.forEach((column, index) => {
        let columnsText = "";
        if (index === 0) columnsText = this.$t("column.total");
        const totalColumnIndex = this.displaySelectedCheckbox ? 4 : 3;

        const currencys = (this.showTotalPrice
          ? orderItems
          : this.getEffectiveOrderItem(orderItems)
        ).map(orderItem => orderItem.priceCurrency);
        this.isOrderItemCurrencyMultiple = this.isCurrencyMultiple(currencys);

        if (index === totalColumnIndex) {
          columnsText = this.addCurrencySymbol(
            this.calculationTotalPrice(orderItems),
            this.$store.getters["bill/billCurrency"]
          );
        }

        sums[index] = columnsText;
      });
      return sums;
    },
    isEditItem(row) {
      return row.edit && row.type === "editCustomItem";
    },
    tableRowClassName({ row }) {
      if (row.selectType === "unSelected" && !this.viewMode) {
        return "unSelected";
      }
      return "";
    }
  }
};
</script>

<style lang="scss" scoped>
.price {
  width: 120px;
}

.billPrice {
  font-size: 16px;
}

.originalPrice {
  text-align: right;
  color: #999999;
}

.editor {
  margin: 20px 0px;

  .el-row {
    margin: 12px 0px;
  }
}

::v-deep .unSelected {
  opacity: 0.5;
}
</style>
