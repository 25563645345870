<template>
  <div>
    <span @click="$emit('click', selectType)">
      <span v-if="selectType === 'unSelected'" key="unSelected">
        <i class="far fa-square" />
      </span>
      <span v-else-if="selectType === 'selected'" key="selected">
        <i class="far fa-check-square" />
      </span>
      <span v-else-if="selectType === 'notSelectedAll'" key="notSelectedAll">
        <i class="far fa-minus-square" />
      </span>
    </span>
  </div>
</template>

<script>
export default {
  props: ["selectType"]
};
</script>
